import React from "react"

import Alert from "./Alert"

const PERMISSIONS_CHANGES_URL =
  "https://help.runn.io/en/articles/9714728-12-aug-24-important-permission-changes-that-will-affect-you"

const PermissionsChangesAlert = () => {
  return (
    <Alert
      intent="warning"
      title="Changes and renaming of Permissions"
      description={
        <div>
          The changes will allow more flexibility and granularity when setting
          users' permissions. We encourage you to check out the changes that we
          are making over the next few weeks and to reach out if you have any
          questions or concerns.
        </div>
      }
      buttonLabel="Permissions Changes"
      onClick={() => window.open(PERMISSIONS_CHANGES_URL, "_blank", "noopener")}
    />
  )
}

export default PermissionsChangesAlert
