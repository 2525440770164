/**
 * @generated SignedSource<<7f50fb3e01a1379c0c2f2a008b4a8cb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PersonChangeWorkstreamMutation$variables = {
  currentWorkstreamId?: number | null | undefined;
  id: number;
  newWorkstreamId?: number | null | undefined;
  projectId: number;
  roleId: number;
};
export type PersonChangeWorkstreamMutation$data = {
  readonly changePersonWorkstream: {
    readonly id: number;
    readonly project: {
      readonly assignments: ReadonlyArray<{
        readonly id: number;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly id: number;
      readonly members: ReadonlyArray<{
        readonly id: number;
        readonly workstream: {
          readonly id: number;
          readonly name: string;
        } | null | undefined;
        readonly workstream_id: number | null | undefined;
      }>;
    } | null | undefined;
  };
};
export type PersonChangeWorkstreamMutation = {
  response: PersonChangeWorkstreamMutation$data;
  variables: PersonChangeWorkstreamMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentWorkstreamId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newWorkstreamId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roleId"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workstream_id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "currentWorkstreamId",
        "variableName": "currentWorkstreamId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "newWorkstreamId",
        "variableName": "newWorkstreamId"
      },
      {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "projectId"
      },
      {
        "kind": "Variable",
        "name": "roleId",
        "variableName": "roleId"
      }
    ],
    "concreteType": "ChangePersonWorkstreamResult",
    "kind": "LinkedField",
    "name": "changePersonWorkstream",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_members",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "workstreams",
                "kind": "LinkedField",
                "name": "workstream",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "assignments",
            "kind": "LinkedField",
            "name": "assignments",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonChangeWorkstreamMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PersonChangeWorkstreamMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "8bfe05e37d26f9ff0fd2e7576a1712b1",
    "id": null,
    "metadata": {},
    "name": "PersonChangeWorkstreamMutation",
    "operationKind": "mutation",
    "text": "mutation PersonChangeWorkstreamMutation(\n  $id: Int!\n  $projectId: Int!\n  $roleId: Int!\n  $currentWorkstreamId: Int\n  $newWorkstreamId: Int\n) {\n  changePersonWorkstream(id: $id, projectId: $projectId, roleId: $roleId, currentWorkstreamId: $currentWorkstreamId, newWorkstreamId: $newWorkstreamId) {\n    id\n    project {\n      id\n      members {\n        id\n        workstream_id\n        workstream {\n          id\n          name\n        }\n      }\n      assignments {\n        id\n        workstream_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b2090bca8e6bb21cf92f56118d0a1d6";

export default node;
