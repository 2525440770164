import cc from "classcat"
import React from "react"
import { match } from "ts-pattern"

import styles from "./Alert.module.css"

import Button, { ButtonProps } from "../buttons/Button"
import { Danger, InfoCircle, Warning } from "../react-icons"

type Props = {
  intent: "primary" | "warning" | "danger"
  title?: JSX.Element | string
  description?: JSX.Element | string
  buttonLabel?: string
  onClick: ButtonProps["onClick"]
}

const Alert = (props: Props) => {
  const { intent, title, description, buttonLabel } = props
  const icon = match(intent)
    .with("primary", () => (
      <InfoCircle fill={"var(--runn-blue)"} className={styles[intent]} />
    ))
    .with("warning", () => <Warning className={styles[intent]} />)
    .with("danger", () => <Danger className={styles[intent]} />)
    .exhaustive()

  return (
    <div className={cc([styles.container, styles[intent]])}>
      {icon}
      <div>
        {title && <div>{title}</div>}
        {description && <div>{description}</div>}
      </div>
      {buttonLabel && (
        <Button onClick={props.onClick} intent={intent} outlined={false}>
          <span>{buttonLabel}</span>
        </Button>
      )}
    </div>
  )
}

export default Alert
