import { Icon, IconName } from "@blueprintjs/core"
import cc from "classcat"
import React, { useEffect, useState } from "react"
import SparkMD5 from "spark-md5"

import styles from "./Avatar.module.css"

import TooltipEllipsis from "./TooltipEllipsis"
import { ArchiveIcon } from "./react-icons"

// @ts-ignore
const windowAllowProfilePhotos = window.allow_profile_photos === "true"

type Props = {
  email?: string
  containerClassName?: string
  className?: string
  size?: number
  initials?: string
  is_placeholder?: boolean
  name?: string | JSX.Element
  nameClassName?: string
  subTitle?: string
  avatar?: string
  maxTextWidth?: string
  isInactivePerson?: boolean
  isArchived?: boolean
  customIcon?: IconName
}

const Avatar = (props: Props) => {
  const {
    initials,
    is_placeholder,
    containerClassName,
    className = "",
    size = 24,
    name,
    subTitle = "",
    avatar,
    email,
    isInactivePerson,
    isArchived,
    nameClassName,
    customIcon,
  } = props

  const [imageUrl, setImageUrl] = useState<string>("")
  const maxTextWidth = props.maxTextWidth ?? "200px"

  useEffect(() => {
    if (windowAllowProfilePhotos) {
      if (avatar) {
        const image = new Image()
        image.onload = () => {
          setImageUrl(avatar)
        }
        image.src = avatar
      } else if (email && email.includes("@runn.io")) {
        const image = new Image()
        const hash = SparkMD5.hash(email.toString().trim().toLowerCase())
        const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?d=404&r=R&s=${size * 2}`

        image.onload = () => {
          setImageUrl(gravatarUrl)
        }

        image.src = gravatarUrl
      }
    }
  }, [avatar, email, size])

  const Body = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {name && (
        <TooltipEllipsis
          style={{ marginLeft: 10 }}
          className={nameClassName}
          text={name}
          customMaxWidth={maxTextWidth}
        />
      )}
      {subTitle && (
        <TooltipEllipsis
          style={{ marginLeft: 10 }}
          text={subTitle}
          customMaxWidth={maxTextWidth}
        />
      )}
    </div>
  )

  if (isArchived) {
    return (
      <div className={cc(["fs-exclude", styles.container, containerClassName])}>
        <p
          data-component="Avatar/archivedIcon"
          className={cc([
            className,
            styles.avatarInitials,
            styles.archivedIcon,
          ])}
          style={{
            minHeight: `${size}px`,
            minWidth: `${size}px`,
            fontSize: size >= 40 ? "16px" : "11px",
          }}
        >
          <ArchiveIcon color="var(--snow)" />
        </p>
        {Body}
      </div>
    )
  }

  if (is_placeholder) {
    return (
      <div className={cc(["fs-exclude", styles.container, containerClassName])}>
        <p
          className={cc([
            className,
            styles.avatarInitials,
            styles.placeholderInitials,
            styles.placeholder,
            {
              [styles.inactivePerson]: isInactivePerson,
            },
          ])}
          style={{
            minHeight: `${size}px`,
            minWidth: `${size}px`,
            fontSize: size >= 40 ? "22px" : "15px",
          }}
        >
          P
        </p>
        {Body}
      </div>
    )
  }

  if (imageUrl) {
    return (
      <div className={cc(["fs-exclude", styles.container, containerClassName])}>
        <img
          className={cc([
            className,
            styles.avatar,
            {
              [styles.inactivePerson]: isInactivePerson,
            },
          ])}
          src={imageUrl}
          alt={initials || "Avatar"}
          style={{ minHeight: `${size}px`, minWidth: `${size}px` }}
          height={size}
          width={size}
        />
        {Body}
      </div>
    )
  }

  if (customIcon) {
    return (
      <div className={cc(["fs-exclude", styles.container, containerClassName])}>
        <p
          className={cc([className, styles.avatarInitials])}
          style={{
            minHeight: `${size}px`,
            minWidth: `${size}px`,
            fontSize: size >= 40 ? "16px" : "11px",
          }}
        >
          <Icon icon={customIcon} color="var(--slate)" />
        </p>
        {Body}
      </div>
    )
  }

  return (
    <div className={cc(["fs-exclude", styles.container, containerClassName])}>
      <p
        data-component="Avatar/avatarInitials"
        className={cc([
          className,
          styles.avatarInitials,
          {
            [styles.inactivePerson]: isInactivePerson,
          },
        ])}
        style={{
          minHeight: `${size}px`,
          minWidth: `${size}px`,
          fontSize: size >= 40 ? "16px" : "11px",
        }}
      >
        {initials}
      </p>
      {Body}
    </div>
  )
}

export default React.memo(Avatar)
