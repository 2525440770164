type TransformOptions = {
  width?: number
  height?: number
  quality?: number | string
}

const buildTransformString = (options: TransformOptions = {}): string => {
  const transform = [] as Array<string>
  const quality = options.quality || "auto"
  if (options.width) {
    transform.push(`w_${options.width * 2}`)
  }
  if (options.height) {
    transform.push(`h_${options.height * 2}`)
  }

  transform.push(`q_${quality}`)

  const transformString = transform.join(",")
  return transformString
}

const buildImageUrl = (
  imageKey: string,
  options: TransformOptions = {},
): string | undefined => {
  if (imageKey == null) {
    return undefined
  }
  const transformString = buildTransformString(options)

  // @ts-ignore
  const env_folder_name = window.CLOUDINARY_FOLDER
  const folder = env_folder_name ? env_folder_name : ""

  const url = [
    "https://res.cloudinary.com/runn/image/upload",
    transformString,
    folder,
    `${imageKey}`,
  ]
    .filter(Boolean)
    .join("/")

  return url
}

export { buildTransformString, buildImageUrl }
