import { graphql } from "react-relay"

import { PersonActivateMutation } from "./__generated__/PersonActivateMutation.graphql"
import { PersonArchiveMutation } from "./__generated__/PersonArchiveMutation.graphql"
import { PersonBulkCreateMutation } from "./__generated__/PersonBulkCreateMutation.graphql"
import { PersonBulkUpdateHolidaysGroupMutation } from "./__generated__/PersonBulkUpdateHolidaysGroupMutation.graphql"
import { PersonBulkUpdateMutation } from "./__generated__/PersonBulkUpdateMutation.graphql"
import { PersonBulkUpdateTeamMutation } from "./__generated__/PersonBulkUpdateTeamMutation.graphql"
import { PersonCreateMutation } from "./__generated__/PersonCreateMutation.graphql"
import { PersonUpdateMutation } from "./__generated__/PersonUpdateMutation.graphql"
import { PersonChangeWorkstreamMutation } from "~/mutations/__generated__/PersonChangeWorkstreamMutation.graphql"

import { environment } from "~/store/hasura"

import {
  addLinkedRecord,
  commitMutationPromise,
  useMutationPromise,
} from "./helpers"
import * as relayids from "~/helpers/relayids"

import { showToast } from "~/containers/ToasterContainer"

const personCreateMutation = graphql`
  mutation PersonCreateMutation(
    $input: PersonCreateInput!
    $plannerStartDate: date
  ) {
    action_person_create(input: $input) {
      person {
        id
        active
        first_name
        last_name
        email
        is_placeholder
        team_id
        tags
        references
        account_id
        archivable
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          employment_type
          rostered_days
          role_id
          job_title
          cost: cost_private
          role {
            id
            name
          }
        }
        competencies {
          id
          level
          skill {
            id
            name
          }
          person {
            id
            first_name
            last_name
          }
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
        }
        time_offs {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          leave_type
          person_id
          note
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        team {
          id
          name
        }
        holidays_group {
          id
        }
        actuals_aggregate {
          aggregate {
            count
          }
        }
        assignments_aggregate {
          aggregate {
            count
          }
        }
        project_memberships {
          id
        }
        links {
          id
          href
          name
          show_in_planner
        }
        ...PersonCustomEditor_person @relay(mask: false)
      }
      account {
        id
        default_full_time_minutes
        teams {
          id
          name
        }
        people {
          id
        }
        people_aggregate(
          where: { active: { _eq: true }, is_placeholder: { _eq: false } }
        ) {
          aggregate {
            count
          }
        }
        people_tags: tags(where: { model: { _eq: "person" } }) {
          id
          name
          model
          archived
        }
        tags {
          id
          name
          model
          archived
        }
        skills {
          id
        }
      }
    }
  }
`

type PersonCreateRelayOptions = {
  variables: PersonCreateMutation["variables"]
}

export const personCreateRelay = async (options: PersonCreateRelayOptions) => {
  const { variables } = options

  const data = await commitMutationPromise<PersonCreateMutation>(environment, {
    mutation: personCreateMutation,
    variables,
  })

  return data.action_person_create.person
}

const personUpdateMutation = graphql`
  mutation PersonUpdateMutation($input: PersonUpdateInput!) {
    action_person_update(input: $input) {
      person {
        id
        active
        first_name
        last_name
        email
        is_placeholder
        team_id
        tags
        references
        account_id
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          employment_type
          rostered_days
          cost: cost_private
          job_title
          role_id
          role {
            id
            name
          }
          person {
            id
          }
        }
        team {
          id
          name
        }
        links {
          id
          href
          name
          show_in_planner
        }
        time_offs {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          leave_type
          person_id
          note
          holiday {
            id
            type
          }
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        competencies {
          id
          level
          person_id
          skill {
            id
            name
          }
          person {
            id
            first_name
            last_name
          }
        }
        holidays_group {
          id
        }
        ...PersonCustomEditor_person @relay(mask: false)
      }
      account {
        id
        teams {
          id
          name
        }
        people {
          id
        }
        people_aggregate(
          where: { active: { _eq: true }, is_placeholder: { _eq: false } }
        ) {
          aggregate {
            count
          }
        }
        people_tags: tags(where: { model: { _eq: "person" } }) {
          id
          name
          model
          archived
        }
        tags {
          id
          name
          model
          archived
        }
        competencies {
          id
        }
        skills {
          id
        }
        invitations {
          id
          email
        }
      }
    }
  }
`

type PersonUpdateRelayOptions = {
  variables: PersonUpdateMutation["variables"]
}

export const personUpdateRelay = (options: PersonUpdateRelayOptions) => {
  const { variables } = options

  return commitMutationPromise<PersonUpdateMutation>(environment, {
    mutation: personUpdateMutation,
    variables,
  })
}

export const activatePerson = (
  variables: PersonActivateMutation["variables"],
) => {
  return commitMutationPromise<PersonActivateMutation>(environment, {
    mutation: graphql`
      mutation PersonActivateMutation($id: Int!) {
        activatePerson(id: $id) {
          person {
            id
            active
          }
          account {
            id
            people_aggregate(
              where: { active: { _eq: true }, is_placeholder: { _eq: false } }
            ) {
              aggregate {
                count
              }
            }
          }
        }
      }
    `,
    variables,
  })
}

export const archivePerson = (
  variables: PersonArchiveMutation["variables"],
) => {
  return commitMutationPromise<PersonArchiveMutation>(environment, {
    mutation: graphql`
      mutation PersonArchiveMutation($id: Int!) {
        archivePerson(id: $id) {
          person {
            id
            active
          }
          account {
            id
            people_aggregate(
              where: { active: { _eq: true }, is_placeholder: { _eq: false } }
            ) {
              aggregate {
                count
              }
            }
          }
        }
      }
    `,
    variables,
  })
}

const personBulkUpdateTeamMutation = graphql`
  mutation PersonBulkUpdateTeamMutation($id: [Int!]!, $team_id: Int) {
    update_people(where: { id: { _in: $id } }, _set: { team_id: $team_id }) {
      returning {
        id
        team_id
        account {
          teams {
            id
            people {
              id
              team_id
              team {
                id
              }
            }
          }
          people {
            id
            team_id
            team {
              id
              name
            }
          }
        }
      }
    }
  }
`

type PersonBulkUpdateTeamMutationOptions = {
  variables: PersonBulkUpdateTeamMutation["variables"]
}

export const personBulkUpdateTeamRelay = (
  options: PersonBulkUpdateTeamMutationOptions,
) => {
  const { variables } = options
  return commitMutationPromise<PersonBulkUpdateTeamMutation>(environment, {
    mutation: personBulkUpdateTeamMutation,
    variables,
  })
}

const personBulkCreateMutation = graphql`
  mutation PersonBulkCreateMutation(
    $input: PersonBulkCreateInput!
    $plannerStartDate: date
  ) {
    action_person_bulk_create(input: $input) {
      person {
        id
        active
        first_name
        last_name
        email
        is_placeholder
        account_id
        tags
        team_id
        references
        archivable
        contracts {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          employment_type
          role {
            id
            name
          }
        }
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
        }
        time_offs {
          id
          start_date: start_date_runn
          end_date: end_date_runn
          ...ExtLinks_TimeOff @relay(mask: false)
        }
        team {
          id
          name
        }
        competencies {
          id
        }
        project_memberships {
          id
        }
        holidays_group {
          id
        }
        actuals_aggregate {
          aggregate {
            count
          }
        }
        assignments_aggregate {
          aggregate {
            count
          }
        }
        ...PersonCustomEditor_person @relay(mask: false)
      }
      account {
        id
        rate_cards {
          id
          name
          role_charge_out_rates {
            role_id
            rate_card_id
            role {
              id
              name
            }
            charge_out_rate: charge_out_rate_private
          }
        }
        people_aggregate(
          where: { active: { _eq: true }, is_placeholder: { _eq: false } }
        ) {
          aggregate {
            count
          }
        }
        roles {
          id
          name
          active
        }
      }
    }
  }
`

type PersonBulkCreateOptions = {
  variables: PersonBulkCreateMutation["variables"]
}

export type PersonBulkCreateResponse =
  PersonBulkCreateMutation["response"]["action_person_bulk_create"]

export const personBulkCreateRelay = async (
  options: PersonBulkCreateOptions,
) => {
  const { variables } = options
  const response = await commitMutationPromise<PersonBulkCreateMutation>(
    environment,
    {
      mutation: personBulkCreateMutation,
      variables,
      updater: (store, data) => {
        const payload = data.action_person_bulk_create
        const accountRelayId = relayids.accounts.encode(
          payload[0].person.account_id,
        )
        const account = store.get(accountRelayId)

        payload.forEach((personData) => {
          // Add the role
          const role = store.get(
            relayids.roles.encode(personData.person.contracts[0].role.id),
          )
          // when creating a person they only have one contract
          // so it's safe to use contracts[0] instead of getCurrentContract()
          addLinkedRecord(account, "roles", role)

          // Add the person
          const person = store.get(relayids.people.encode(personData.person.id))
          addLinkedRecord(account, "people", person)
        })
      },
    },
  )

  showToast({
    message: "People successfully created",
    type: "success",
  })

  return response.action_person_bulk_create
}

const personBulkUpdateMutation = graphql`
  mutation PersonBulkUpdateMutation($input: PersonBulkUpdateInput!) {
    action_person_bulk_update(input: $input) {
      account {
        id
        people {
          id
          active
          first_name
          last_name
          email
          tags
          team {
            id
            name
          }
          contracts {
            id
            minutes_per_day
            cost: cost_private
            employment_type
            start_date: start_date_runn
            end_date: end_date_runn
            role {
              id
              name
              default_hour_cost: default_hour_cost_private
            }
            job_title
          }
        }
        people_aggregate(
          where: { active: { _eq: true }, is_placeholder: { _eq: false } }
        ) {
          aggregate {
            count
          }
        }
        roles {
          id
        }
      }
    }
  }
`

type PersonBulkUpdateOptions = {
  variables: PersonBulkUpdateMutation["variables"]
}

export const personBulkUpdateRelay = async (
  options: PersonBulkUpdateOptions,
) => {
  const { variables } = options
  const data = await commitMutationPromise<PersonBulkUpdateMutation>(
    environment,
    {
      mutation: personBulkUpdateMutation,
      variables,
    },
  )

  const people = data.action_person_bulk_update.account.people
  if (people) {
    showToast({
      message: "People successfully updated",
      type: "success",
    })
  }
  return people
}

const personBulkUpdateHolidaysGroupMutation = graphql`
  mutation PersonBulkUpdateHolidaysGroupMutation(
    $id: [Int!]!
    $current_holidays_group_id: Int
    $new_holidays_group_id: Int
  ) {
    update_people(
      where: { id: { _in: $id } }
      _set: { holidays_group_id: $new_holidays_group_id }
    ) {
      returning {
        id
        account {
          id
          people(where: { is_placeholder: { _eq: false } }) {
            id
            holidays_group {
              id
            }
          }
          holidays_groups(where: { id: { _eq: $current_holidays_group_id } }) {
            id
            people_aggregate(distinct_on: id) {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
`

type PersonBulkUpdateHolidaysGroupMutationOptions = {
  variables: PersonBulkUpdateHolidaysGroupMutation["variables"]
}

export const personBulkUpdateHolidaysGroupRelay = (
  options: PersonBulkUpdateHolidaysGroupMutationOptions,
) => {
  const { variables } = options
  return commitMutationPromise<PersonBulkUpdateHolidaysGroupMutation>(
    environment,
    {
      mutation: personBulkUpdateHolidaysGroupMutation,
      variables,
    },
  )
}

const personChangeWorkstreamMutation = graphql`
  mutation PersonChangeWorkstreamMutation(
    $id: Int!
    $projectId: Int!
    $roleId: Int!
    $currentWorkstreamId: Int
    $newWorkstreamId: Int
  ) {
    changePersonWorkstream(
      id: $id
      projectId: $projectId
      roleId: $roleId
      currentWorkstreamId: $currentWorkstreamId
      newWorkstreamId: $newWorkstreamId
    ) {
      id
      project {
        id
        members {
          id
          workstream_id
          workstream {
            id
            name
          }
        }
        assignments {
          id
          workstream_id
        }
      }
    }
  }
`

export const usePersonChangeWorkstreamMutation = () => {
  return useMutationPromise<PersonChangeWorkstreamMutation>(
    personChangeWorkstreamMutation,
  )
}
