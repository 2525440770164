/**
 * @generated SignedSource<<537ab2a8135faec40d5e946d809789f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountDropdownMenu_account$data = {
  readonly account: {
    readonly account_type: string;
    readonly alternative_account: {
      readonly account_type: string;
    } | null | undefined;
    readonly currency: string | null | undefined;
    readonly default_full_time_minutes: number;
    readonly id: number;
    readonly name: string;
    readonly timesheets_enabled: boolean | null | undefined;
  } | null | undefined;
  readonly current_user: {
    readonly email: string;
    readonly first_name: string | null | undefined;
    readonly id: number;
    readonly last_name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_user">;
  } | null | undefined;
  readonly user_accounts: ReadonlyArray<{
    readonly account: {
      readonly account_type: string;
      readonly alternative_account_id: number | null | undefined;
      readonly id: number;
      readonly name: string;
    };
    readonly id: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ServerFlagsProvider_gql">;
  readonly " $fragmentType": "AccountDropdownMenu_account";
};
export type AccountDropdownMenu_account$key = {
  readonly " $data"?: AccountDropdownMenu_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountDropdownMenu_account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "account_type",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "accountId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountDropdownMenu_account",
  "selections": [
    {
      "alias": "account",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "accountId"
        }
      ],
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts_by_pk",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timesheets_enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "accounts",
          "kind": "LinkedField",
          "name": "alternative_account",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default_full_time_minutes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "users",
      "kind": "LinkedField",
      "name": "current_user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PermissionsProvider_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "user_accounts",
      "kind": "LinkedField",
      "name": "user_accounts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "accounts",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "alternative_account_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ServerFlagsProvider_gql"
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "b67836ac423218a0f7b5925e3f568efe";

export default node;
