import { FlagsProvider } from "flagged"
import React, { useMemo } from "react"
import { graphql, useFragment } from "react-relay"

import { ServerFlagsProvider_gql$key } from "./__generated__/ServerFlagsProvider_gql.graphql"

import { register } from "~/helpers/analytics"

import buildFeatureList from "./buildFeatureList"

type Props = {
  gql: ServerFlagsProvider_gql$key
  children: React.ReactNode
}

const FRAGMENT = graphql`
  fragment ServerFlagsProvider_gql on query_root {
    features {
      id
      enabled
      description
      name
    }
    features_accounts {
      id
      feature_id
      enabled
    }
  }
`

const ServerFlagsProvider = (props: Props) => {
  const { children, gql } = props
  const data = useFragment(FRAGMENT, gql)

  const featureList = useMemo(() => {
    const builtFeatureList = buildFeatureList(data)
    register({ features: builtFeatureList })
    return builtFeatureList
  }, [data])

  return <FlagsProvider features={featureList}>{children}</FlagsProvider>
}

export default ServerFlagsProvider
