import { format as formatDate, parseISO } from "date-fns"
import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import { EditHolidaysGroupForm_account$key } from "./__generated__/EditHolidaysGroupForm_account.graphql"
import { EditHolidaysGroupForm_holidays_group$key } from "./__generated__/EditHolidaysGroupForm_holidays_group.graphql"

import { track } from "~/helpers/analytics"
import { reportError } from "~/helpers/error-helpers"
import { CustomHoliday, PublicHoliday } from "~/helpers/holiday-helpers"

import Input from "~/common/Input"
import { ModalFormWrapper } from "~/common/ModalForm"

import {
  holidaysBulkUpdateRelay,
  holidaysGroupUpdateRelay,
} from "~/mutations/Holidays"

import {
  HOLIDAYS_DATE_RANGES,
  HOLIDAYS_YEARS,
  HOLIDAY_TYPES,
} from "~/GLOBALVARS"
import HolidaysGroupForm from "~/Holidays/Forms/HolidaysGroupForm/HolidaysGroupForm"

type Props = {
  account: EditHolidaysGroupForm_account$key
  holidaysGroup: EditHolidaysGroupForm_holidays_group$key
  closeDialog: () => void
  defaultSelectedTabId?: "public" | "custom"
}

const formatHolidaysForMutation = (holidays, groupId, holidayType) => {
  const isPublicHoliday = holidayType === HOLIDAY_TYPES.PUBLIC

  return holidays.reduce((acc, holiday) => {
    const holidayData = {
      name: isPublicHoliday ? holiday.holiday.name : holiday.name,
      holidays_group_id: groupId,
      type: holidayType,
    }

    const processedHolidays = []
    const years = ["currentYear", "secondYear", "thirdYear"]
    years.forEach((year) => {
      if (holiday[year]) {
        processedHolidays.push({
          ...holidayData,
          ...(isPublicHoliday
            ? {
                date: holiday[year].date,
                observed: holiday[year].observed,
                holiday_api_uuid: holiday?.holiday?.uuid,
              }
            : {
                date: formatDate(holiday[year].date, "yyy-MM-dd"),
                ...(holiday[year].id && { id: holiday[year].id }),
              }),
        })
      }
    })

    return [...acc, ...processedHolidays]
  }, [])
}

const getYearName = (date) =>
  date.includes(HOLIDAYS_YEARS.CURRENT_YEAR)
    ? "currentYear"
    : date.includes(HOLIDAYS_YEARS.SECOND_YEAR)
      ? "secondYear"
      : date.includes(HOLIDAYS_YEARS.THIRD_YEAR)
        ? "thirdYear"
        : "-"

const groupHolidaysByHolidayName = (savedHolidays, holidayType) => {
  const holidays = savedHolidays.filter(
    (holiday) => holiday.type === holidayType,
  )

  return holidays.reduce((acc, holiday) => {
    const { id, holiday_api_uuid, name, date, observed } = holiday

    const holidayDate = observed || date
    const yearName = getYearName(holidayDate)
    const dateData = {
      date: holidayType === HOLIDAY_TYPES.CUSTOM ? parseISO(date) : date,
      ...(holidayType === HOLIDAY_TYPES.PUBLIC && { observed: holidayDate }),
      ...(holidayType === HOLIDAY_TYPES.CUSTOM && { id }),
    }

    const holidayItem = acc.find((item) => item.name === name)
    if (holidayItem) {
      holidayItem[yearName] = dateData
      return acc
    }

    const newHolidayItem = {
      name,
      type: holidayType,
      [yearName]: dateData,
      ...(holidayType === HOLIDAY_TYPES.PUBLIC && { holiday_api_uuid }),
    }
    return [...acc, newHolidayItem]
  }, [])
}

const EditHolidaysGroupForm = (props: Props) => {
  const { closeDialog, defaultSelectedTabId } = props

  const { allHolidaysGroups } = useFragment<EditHolidaysGroupForm_account$key>(
    graphql`
      fragment EditHolidaysGroupForm_account on accounts {
        allHolidaysGroups: holidays_groups {
          id
          name
        }
      }
    `,
    props.account,
  )

  const holidaysGroup = useFragment<EditHolidaysGroupForm_holidays_group$key>(
    graphql`
      fragment EditHolidaysGroupForm_holidays_group on holidays_groups {
        id
        name
        region_name
        country_name
        country_code
        holidays(where: { date: { _gte: $minDate, _lte: $maxDate } }) {
          id
          holiday_api_uuid
          name
          date
          observed
          type
        }
      }
    `,
    props.holidaysGroup,
  )

  const [groupName, setGroupName] = useState<string>("" || holidaysGroup.name)
  const [publicHolidays, setPublicHolidays] = useState<PublicHoliday[]>([])
  const [customHolidays, setCustomHolidays] = useState<CustomHoliday[]>([])
  const [showDuplicateError, setShowDuplicateError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const savedHolidays = {
    public: groupHolidaysByHolidayName(
      holidaysGroup.holidays,
      HOLIDAY_TYPES.PUBLIC,
    ),
    custom: groupHolidaysByHolidayName(
      holidaysGroup.holidays,
      HOLIDAY_TYPES.CUSTOM,
    ).sort(
      (a, b) =>
        // Some years may not have a value so fallback to following year
        (a.currentYear?.date || a.secondYear?.date || a.thirdYear?.date) -
        (b.currentYear?.date || b.secondYear?.date || b.thirdYear?.date),
    ),
  }

  const onInputChange = (e) => {
    const name = e.target.value
    setGroupName(name)

    const allHolidaysGroupsExcludingCurrent = holidaysGroup
      ? allHolidaysGroups.filter((hg) => hg.name !== holidaysGroup.name)
      : allHolidaysGroups
    if (
      name !== holidaysGroup.name &&
      allHolidaysGroupsExcludingCurrent.some(
        (group) =>
          name.toLowerCase().trim() === group.name.toLowerCase().trim(),
      )
    ) {
      return setShowDuplicateError(true)
    }
    setShowDuplicateError(false)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const holidaysToDelete = holidaysGroup.holidays.reduce(
      (acc, savedHoliday) => {
        const holidayExists =
          publicHolidays.some(
            (holiday) =>
              holiday.holiday.uuid === savedHoliday.holiday_api_uuid &&
              holiday.holiday.name === savedHoliday.name,
          ) ||
          customHolidays.some(
            (holiday) =>
              holiday.currentYear?.id === savedHoliday.id ||
              holiday.secondYear?.id === savedHoliday.id ||
              holiday.thirdYear?.id === savedHoliday.id,
          )

        return holidayExists ? acc : [...acc, savedHoliday.id]
      },
      [],
    )
    const publicHolidaysToAdd = publicHolidays.filter(
      ({ holiday }) =>
        !savedHolidays.public.some(
          (savedHoliday) =>
            savedHoliday.holiday_api_uuid === holiday.uuid &&
            holiday.name === savedHoliday.name,
        ),
    )
    const formattedPublicHolidaysToAdd = formatHolidaysForMutation(
      publicHolidaysToAdd,
      holidaysGroup.id,
      HOLIDAY_TYPES.PUBLIC,
    )
    const formattedCustomHolidays = formatHolidaysForMutation(
      customHolidays,
      holidaysGroup.id,
      HOLIDAY_TYPES.CUSTOM,
    )
    const holidaysGroupId = holidaysGroup.id
    try {
      await holidaysGroupUpdateRelay({
        id: holidaysGroupId,
        input: { name: groupName },
      })
      await holidaysBulkUpdateRelay({
        holidaysGroupId,
        ...HOLIDAYS_DATE_RANGES,
        input: {
          holidays_group_id: holidaysGroupId,
          holidays_to_delete_ids: holidaysToDelete,
          public_holidays_to_add: formattedPublicHolidaysToAdd,
          custom_holidays_to_add: formattedCustomHolidays,
        },
      })

      holidaysToDelete.length && track("Holiday Delete")
      formattedPublicHolidaysToAdd.length && track("Public Holiday Add")
      formattedCustomHolidays.length && track("Custom Holiday Add")
    } catch (error) {
      void reportError(`edit holiday group error`, error)
    } finally {
      setIsLoading(false)
      closeDialog()
    }
  }

  const InputFields = (
    <Input
      label="Group Name"
      value={groupName}
      placeholder="e.g. London"
      error={!groupName || showDuplicateError}
      customErrorMessage={showDuplicateError ? "exists" : "is required"}
      onChange={onInputChange}
      dataTest={"edit-holidays-group-name-input"}
      autoFocus
    />
  )

  return (
    <ModalFormWrapper
      wide
      headerTitle={`Edit ${holidaysGroup.name}`}
      headerSubTitle={
        holidaysGroup.region_name
          ? `${holidaysGroup.region_name}, ${holidaysGroup.country_name}`
          : holidaysGroup.country_name
      }
    >
      <HolidaysGroupForm
        InputFields={InputFields}
        locationCode={holidaysGroup.country_code}
        holidaysGroup={holidaysGroup}
        publicHolidays={publicHolidays}
        customHolidays={customHolidays}
        disableSubmitButton={!groupName || showDuplicateError}
        savedHolidays={savedHolidays}
        isLoading={isLoading}
        setPublicHolidays={setPublicHolidays}
        setCustomHolidays={setCustomHolidays}
        closeDialog={closeDialog}
        onSubmit={onSubmit}
        defaultSelectedTabId={defaultSelectedTabId}
      />
    </ModalFormWrapper>
  )
}

export default EditHolidaysGroupForm
