import mixpanel from "mixpanel-browser"

import { getEnvInfo } from "./environment"
import { captureEvent } from "./error-helpers"

// Works around static binding issues: This gets executed
// before the app had a chance to assign the window object.
const getAnalytics = (): ReturnType<typeof createAnalytics> =>
  (window as any).runnAnalytics

// Normalised "path" to aid analytics that don't understand them (like Mixpanel).
const getNormalizedPath = (): string => {
  // Replace Rails-style edit links with IDs.
  // There's no good way to tell apart hashids from controller actions.
  const path = (window as any).location.pathname
    // "users/e193c1/edit" -> "users/edit"
    // "users/e193c1" -> "users/edit"
    .replace(
      /(projects|people|clients|rate_cards|roles|tags|teams|users)\/.+/,
      "$1/edit",
    )

  // Filters out user-specific path properties and most query params.
  const allowedKeys = ["name"]
  const params = new URLSearchParams(window.location.search)
  const filteredParams = new URLSearchParams()
  allowedKeys.forEach((param) => {
    if (params.has(param)) {
      filteredParams.append(param, params.get(param) ?? "")
    }
  })

  return filteredParams.toString()
    ? `${path}?${filteredParams.toString()}`
    : path
}

type Config = {
  token: string
  userId?: string
  accountId?: string
  defaultProps?: Record<string, any>
}

// Lightweight wrapper to minimise wire-up inside consumers,
// and keep vendor-specific implementation out of them.
// By using a factory function,
// we retain the ability to get the config from async calls throughout app boot.
// This is purposely simple (no adapters/plugins) for now.
export const createAnalytics = (config?: Config) => {
  const isConfigured = config
  const envInfo = getEnvInfo(window.location.href)
  if (config) {
    mixpanel.init(config.token, { debug: envInfo.env !== "production" })

    // Sends context on every event
    // See https://developer.mixpanel.com/docs/javascript#super-properties
    if (config.defaultProps) {
      mixpanel.register(config.defaultProps)
    }

    // Identify the user (or generate an identifier for null users).
    // The default properties should take care of the group association
    if (config.userId) {
      mixpanel.identify(config.userId)
    }

    // Events automatically contain the URL path
    mixpanel.track("Page View", {
      normalized_path: getNormalizedPath(),
    })
  }

  return {
    identify: (userId: string) => {
      if (isConfigured) {
        mixpanel.identify(userId)
      }
    },
    // Additional props only, most context will be tracked with default props
    track: (event: string, props?: Record<string, any>) => {
      if (isConfigured) {
        // TODO Incorporate page context
        mixpanel.track(event, props)
      }

      // Track in application error monitoring for additional context
      // in case an error is caused soon after this event
      captureEvent({ event, ...(props || {}) }, "info")
    },
    register: (props: Record<string, any>) => {
      if (isConfigured) {
        mixpanel.register(props)
      }
    },
  }
}

export const identify = (userId: string) => getAnalytics().identify(userId)

export const track = (event: string, props?: Record<string, any>) =>
  getAnalytics().track(event, props)

export const register = (props: Record<string, any>) =>
  getAnalytics().register(props)

export default { createAnalytics, identify, track, register }
