import { TooltipProps } from "@blueprintjs/core"
import cc from "classcat"
import React, { useState } from "react"

import styles from "./CompetencyDropdown.module.css"

import { Popover2 } from "~/common/Popover2"
import { CompetencyIcon } from "~/common/react-icons"

import CompetencyTooltipLevel from "./CompetencyTooltipLevel"

type Props = {
  children: React.ReactElement
  onSelectLevel: (level: number, e?: any) => void
  defaultLevel?: number
  disabled?: boolean
  tooltipProps?: Omit<TooltipProps, "content">
}

const COMPETENCY_LEVELS = [1, 2, 3, 4, null]

const CompetencyDropdown = (props: Props) => {
  const { defaultLevel = null, onSelectLevel, disabled, tooltipProps } = props

  const [isOpen, setIsOpen] = useState(false)

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const openDropdown = (e) => {
    e.stopPropagation()
    if (disabled) {
      return null
    }
    setIsOpen(true)
  }

  const handleSelectLevel = (selectedLevel: number, e) => {
    // Don't track mixpanel here as it is just selecting the level not making actual changes
    // Instead add to 'onSelectLevel' in parent if needed
    onSelectLevel(selectedLevel, e)
    closeDropdown()
  }

  return (
    <Popover2
      content={
        <div className={styles.dropdown}>
          {COMPETENCY_LEVELS.map((level) => (
            <div
              key={level}
              className={cc([
                styles.level,
                {
                  [styles.active]: level === defaultLevel,
                },
              ])}
              data-test={`level-${level || 0}`}
              onClick={(e) => handleSelectLevel(level, e)}
            >
              <CompetencyIcon level={level} />
              <div>{level || "-"}</div>
            </div>
          ))}
        </div>
      }
      placement="bottom"
      isOpen={isOpen}
      onClose={closeDropdown}
    >
      <CompetencyTooltipLevel
        level={defaultLevel}
        canEdit={true}
        tooltipProps={tooltipProps}
      >
        <div
          onClick={openDropdown}
          className={cc([
            styles.dropdownTarget,
            {
              [styles.active]: isOpen,
            },
          ])}
        >
          {props.children}
        </div>
      </CompetencyTooltipLevel>
    </Popover2>
  )
}

export default CompetencyDropdown
